<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group
            label="Company"
            label-for="account-company"
          >
            <b-form-input
              v-model="optionsLocal.company.name"
              name="company"
              placeholder="Company name"
              readonly
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              placeholder="Email"
              readonly
            />

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="submit"
            :disabled="!isValid() || isLoading"
          >
            {{ (isLoading) ? "Tunggu..." : "Simpan" }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-2"
            @click.prevent="resetForm"
            :disabled="isLoading"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      isLoading: false
    }
  },
  methods: {
    isValid() {
      return this.optionsLocal.name !== "" && this.optionsLocal.name !== null && this.optionsLocal.name !== this.generalData.name
    },
    submit() {
      this.isLoading = true

      const requestData = {
        name: this.optionsLocal.name
      }

      this.$http.put('/admin/v1/profile', requestData).then(res => { 
          const data = res.data
          const message = data.message

          this.isLoading = false

          if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })

          }
          else {
              let newUserData = getUserData()

              newUserData.name = this.optionsLocal.name

              localStorage.setItem('userData', JSON.stringify(newUserData))

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })
          }
        })
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup() {
  },
}
</script>
